import React from "react"
import LayOut from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components";




const datas = [
{
tittle: 'رؤية الكلية',
pragraph: ' كلیة علوم ذوي الاحتیاجات الخاصة كلیة متمیزة على المستوى القومي تمد المجتمع بكوادر بشریة مؤھلة علمیاً ومھنیاً باستخدام التقنیات الحدیثة في التعلیم تمكنھم من إجراء البحوث عالیة الجودة والتي تقابل الاحتیاجات المجتمعیة لذوي الاحتیاجات الخاصة؛ بما یؤھلھم للعمل في مجالات التربیة الخاصة سواء في المدراس أو المستشفیات أو الجمعیات المھتمة بذوي الاحتیاجات'
},
{
tittle: 'رساله الكليه',
pragraph: 'كلیة علوم ذوي الاحتیاجات الخاصة مؤسسة للتعلیم العالي والبحث العلمي في مجال ذوي الاحتیاجات الخاصة؛ لتحقیق رسالة الجامعة بما یتوافق مع التقالید والقیم الأخلاقیة والدینیة عن طریق تقدیم أفضل الخدمات التعلیمیة للطلاب في مجال التربیة الخاصة في البكالوریوس والدراسات العلیا لاكسابھم المھارات المطلوبة في التخصص من خلال برامج تعلیمیة وفقاً للاحتیاجات المحلیة والمعاییر العالمیة. إعداد القوى البشریة ذات الكفاءة العلمیة والمھنیة اللازمة للعمل في مجالات ذوي الاحتیاجات الخاصة بتخصصاتھا المختلفة. تلبیة حاجات المؤسسات المجتمعیة من الكوادر البشریة المؤھلة علمیاً ومھنیاً لتقدیم الخدمات التأھیلیة لذوي الاحتیاجات الخاصة. المساھمة في تقدیم الخدمات التوجیھیة والإرشادیة لذوي الاحتیاجات الخاصة وأسرھم. توظیف موارد كلیة علوم ذوي الاحتیاجات الخاصة وإمكانیاتھا البحثیة وخبراتھا الاستشاریة للمساھمة في حل مشكلات فئات ذوي الاحتیاجات الخاصة ودمجھم في المجتمع. الارتقاء بالبحث العلمي في مجالات التربیة الخاصة من خلال إجراء البحوث العلمیة المتقدمة في مجال الوقایة والتشخیص والعلاج لذوي الاحتیاجات الخاصة التي یقوم بھا أعضاء ھیئة التدریس والباحثین في مرحلتي الماجستیر والدكتوراه. '
},
{
tittle: 'أهداف الكلية',
pragraph: 'تحقیق ھدف من أھداف الجامعة المتمثل في ربط الجامعة بالمجتمع في تقدیم الخدمات لذوي الاحتیاجات الخاصة. - تبادل الزیارات بین أعضاء ھیئة التدریس من الكلیة ومثیلھم من الخارج؛ للوقوف على كل ما ھو جدید في مجالات ذوي الاحتیاجات الخاصة. - إرسال البعثات العلمیة في مجال التربیة الخاصة. - التواصل الفعال بین الكلیة ومثیلاتھا إقلیمیاً وعالمیاً من خلال عقد مؤتمرات علمیة متخصصة في مجالات ذوي الاحتیاجات الخاصة. عمل مجلة علمیة متخصصة بالكلیة لنشر الأبحاث المتعلقة بذوي الاحتیاجات الخاصة '
},
{
tittle: 'الاهداف الاستراجيه',
pragraph: ' استخدام نظام معلوماتي متكامل بھدف إدارة كلیة علوم ذوي الاحتیاجات إدارة إلكترونیة. تنمیة مھارات أعضاء ھیئة التدریس في مجال ذوي الاحتیاجات الخاصة. تطویر آلیات المتابعة والتقییم للكلیة. تنمیة الموارد الذاتیة للكلیة لتدعیم العملیة التعلیمیة والبحثیة من خلال إنشاء مراكز متخصصة لتقدیم خدمات لأفراد المجتمع من ذوي الاحیتاجات الخاصة. تعمیق استخدام تكنولوجیا المعلومات في العملیة التعلیمیة بالكلیة. توفیر بیئة دراسیة متمیزة لمساعدة الطلاب على التحصیل العلمي الأمثل من خلال أحدث المعامل الدراسیة. إعداد كوادر بشریة حاصلة على درجة الماجستیر والدكتوراه في أحد مجالات ذوي الاحتیاجات الخاصة..'
},
{
tittle: 'المستفيدون من إنشاء الكلية',
pragraph: 'الطلاب الحاصلون على الثانویة العامة الذین یرغبون في الالتحاق بكلیة علوم ذوي الاحتیاجات الخاصة للعمل في مھنة التعلیم أو العمل في المؤسسات المجتمعیة المھتمة بذوي الاحتیاجات الخاصة. خریجي الكلیات الجامعیة المختلفة الراغبین في الالتحاق في الدراسات العلیا بكلیة علوم ذوي الاحتیاجات الخاصة لما لھا من أھمیة داخلیة وخارجیة وتتیح لھم فرص العمل في مجالات عدة. ذوي الاحتیاجات الخاصة أنفسھم الراغبین في الالتحاق بالكلیة من الموھوبین وذوي الإعاقة البصریة وذوي صعوبات التعلم وغیرھم الذین یحصلون على خبرات: معرفیة، وجدانیة، انفعالیة، ومھارات تمكنھم من الدراسة الجامعیة. یساعد وجود كلیة علوم ذوي الاحتیاجات الخاصة على توعیة العاملین بالحقل التربوي بأھمیة دمج ذوي الاحتیاجات الخاصة مع العادیین مما یؤدي إلى تآلف كافة فئات المجتمع. الانتقال بتوجھات البحث العلمي إلى مجالات ذوي الاحتیاجات الخاصة بما یعود بالفائدة على ذوي الاحتیاجات الخاصة وأسرھم والمجتمع ككل '
},
];




export default () => {

return(
  <LayOut
  background="/img_1.jpg"
  >
     <SEO
       lang="ar"
       title="ahmed elkordy"
       description="dev by ahmed elkordy"
       meta="xxx xxx xxx xxx"
      />



     <div  style={{textAlign: 'center'}}>

      {datas.map((data, index) =>(
        <React.Fragment key={index}>
           <Tittle> {data.tittle} </Tittle>
           <Pragraph> {data.pragraph} </Pragraph>
        </React.Fragment>
      ))}


     </div>



  </LayOut>
)
}







const Tittle = styled.h3`
    color: #fd4d40;
    font-family: "machauerglas", cursive !important;
    font: bold 22px Helvetica, sans-serif;
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
`


const Pragraph = styled.p`


`
